<template>
  <div>
    <table width="100%">
      <tr class="scoringHeader">
        <td colspan="2">Medical Decision Making ({{worksheet.decisionMaking2021 ? '2021' : worksheet.decisionMaking2023 ? '2023' : ''}} EM Guidelines)</td>
        <td>{{scoring.decisionComplexity2021}}</td>
      </tr>
      <tr>
        <td>Type and Number of Presenting Problems</td>
        <td>Score: {{scoring.presentingProblemScore2021}}</td>
        <td>{{scoring.presentingProblem2021}}</td>
      </tr>
      <tr>
        <td>Management Options</td>
        <td>Score: {{scoring.managementOptionsScore2021}}</td>
        <td>{{scoring.managementOptions2021}}</td>
      </tr>
      <tr>
        <td>Amount and Complexity of Data</td>
        <td>Score: {{scoring.documentationComplexityScore2021}}</td>
        <td>{{scoring.documentationComplexity2021}}</td>
      </tr>
      <tr v-if="scoring.timeBasedCalculation">
        <td>Time Based</td>
        <td>Total Time Spent:</td>
        <td>{{scoring.timeSpentTotal}}</td>
      </tr>
    </table>

  </div>
</template>

<script>
  export default {
    name: 'ProWorksheetMDM2021',
    props: ['scoring', 'worksheet'],
    components: {
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped>

</style>