<template>
  <div>
    <div v-for="codeType in getCodeTypes(checklists)" :key="codeType">
      <div v-for="(checklist, index) in getUniqueChecklists(checklists, codeType)" :key="index">
        <table class="finding">
          <tr style="background-color: lightgray; color: black; text-align: center;">
            <th>
              <i>{{ codeType }}</i>{{ ` - ${checklist.checkListName}` }}
            </th>
          </tr>
        </table>

        <table class="details" style="border-top-left-radius: 0px; border-top-right-radius: 0px;">
          <tbody>
            <tr v-for="(item, index) in checklist.checklistItems" :key="index">
              <td width="11%" style="color: black;">
                {{item.itemName}}
              </td>
              <td width="11%" style="color: black;">
                <div v-if="item.answer">
                  <label style="margin-right: 25px">
                    <input type="radio" v-model="item.answer" :value="1" disabled class="custom-radio" /> Yes
                  </label>
                  <label style="margin-right: 25px">
                    <input type="radio" v-model="item.answer" :value="2" disabled class="custom-radio" /> No
                  </label>
                  <label style="margin-right: 25px;">
                    <input type="radio" v-model="item.answer" :value="3" disabled class="custom-radio" /> NA
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="checklists.length === 0">
      <div style="font-style: italic; font-size: 14px; ">No checklists on Worksheet</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProWorksheetchecklists',
    props: ['checklists'],
    components: {},
    data() {
      return {}
    },
    methods: {
      getCodeTypes(arr) {
        const codeTypes = [...new Set(arr.map(x => x.codeDesc))]
        return codeTypes.sort((a, b) => (a > b ? 1 : -1))
      },
      getUniqueChecklists(checklists, codeType) {
        const checklistsForCodeType = checklists.filter(x => x.codeDesc === codeType)
        const uniqueChecklists = [...new Set(checklistsForCodeType.map(x => x.checklistName))]
        return uniqueChecklists.map(x => {
          return {
            checkListName: x,
            checklistItems: checklistsForCodeType.filter(y => x === y.checklistName)
          }
        })
      }
    }
  }
</script>

<style scoped>
  .custom-radio {
    appearance: none;
    -webkit-appearance: none;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: relative;
    background-color: white !important;
  }

  /* .custom-radio:checked {
    background-color: lightblue;
  } */

  .custom-radio:disabled {
    background-color: white;
  }

  .custom-radio:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    background-color: rgb(182, 194, 207);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
</style>