<template>
  <div class="worksheet">
    <table class="demographics" id="demographics">
      <tr>
        <td class="strong">Project ID:</td>
        <td colspan="3">{{ worksheet.projectId }}</td>
      </tr>
      <tr>
        <td class="strong">Hospital:</td>
        <td>{{ worksheet.clientName }}</td>
        <td class="strong">Review Date:</td>
        <td>{{ formatDate(worksheet.reviewDate) }}</td>
      </tr>
      <tr>
        <td class="strong">Auditor:</td>
        <td>{{ worksheet.reviewer }}</td>
        <td class="strong">Patient Account Number:</td>
        <td>{{ worksheet.patAcctNum }}</td>
      </tr>
    </table>

    <table class="demographics" style="margin-top: -1px;">
      <template v-if="worksheet.patientAge === 0">
        <tr>
          <td class="strong">Patient Age:</td>
          <td>{{ worksheet.patientAge }}</td>
          <td class="strong">Patient Sex:</td>
          <td>{{ worksheet.patientSex }}</td>
          <td class="strong">Patient DOB:</td>
          <td>{{ formatDate(worksheet.patientDob) }}</td>
        </tr>
        <tr>
          <td class="strong">Newborn Weight:</td>
          <td>{{ worksheet.newbornWeight ? `${worksheet.newbornWeight}g` : null }}</td>
          <td class="strong">Medical Record Number:</td>
          <td>{{ worksheet.mrNumber }}</td>
          <td class="strong">Coder Query:</td>
          <td>{{ worksheet.coderQuery ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <td class="strong">Coder Query Missed:</td>
          <td>{{ worksheet.coderQueryMissed ? 'Yes' : 'No' }}</td>
          <td class="strong">Coder:</td>
          <td>{{ worksheet.coder }}</td>
          <td class="strong">CDI Query:</td>
          <td>{{ worksheet.cdiQuery ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <td class="strong">CDI Query Missed:</td>
          <td>{{ worksheet.cdiQueryMissed ? 'Yes' : 'No' }}</td>
          <td class="strong">CDI Staff:</td>
          <td>{{ worksheet.cdiStaff }}</td>
          <td class="strong">Auditor Query Affects DRG:</td>
          <td>{{ worksheet.auditQuery ? 'Yes' : 'No' }}</td>
        </tr>
        <tr>
          <td class="strong">Payor: <br />Payor Detail:</td>
          <td style="vertical-align: top;">{{ worksheet.payorName }}<br />{{ worksheet.payorDetail }}</td>
          <td class="strong">D/C Available for Coding:</td>
          <td>{{ worksheet.dcSummAvailAtCoding }}</td>
          <td class="strong">Length of Stay:</td>
          <td>{{ worksheet.los }}</td>
        </tr>
        <tr>
          <td class="strong">Discharge Date:</td>
          <td>{{ formatDate(worksheet.dischargeDate) }}</td>
          <td class="strong">Admit Date:</td>
          <td>{{ formatDate(worksheet.admitDate) }}</td>
          <td class="strong">UB Available:</td>
          <td>{{ worksheet.noUBAvailable ? 'No' : 'Yes' }}</td>
        </tr>
        <tr>
          <td class="strong">Physician:</td>
          <td>{{ worksheet.physician }}</td>
          <td class="strong">Reason for Change:</td>
          <td>{{ worksheet.reasonForChangeDesc }}</td>
          <td class="strong"></td>
          <td></td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td class="strong">Patient Age:</td>
          <td>{{ worksheet.patientAge }}</td>
          <td class="strong">Patient Sex:</td>
          <td>{{ worksheet.patientSex }}</td>
          <td class="strong">Medical Record Number:</td>
          <td>{{ worksheet.mrNumber }}</td>
        </tr>
        <tr>
          <td class="strong">Coder Query:</td>
          <td>{{ worksheet.coderQuery ? 'Yes' : 'No' }}</td>
          <td class="strong">Coder Query Missed:</td>
          <td>{{ worksheet.coderQueryMissed ? 'Yes' : 'No' }}</td>
          <td class="strong">Coder:</td>
          <td>{{ worksheet.coder }}</td>
        </tr>
        <tr>
          <td class="strong">CDI Query:</td>
          <td>{{ worksheet.cdiQuery ? 'Yes' : 'No' }}</td>
          <td class="strong">CDI Query Missed:</td>
          <td>{{ worksheet.cdiQueryMissed ? 'Yes' : 'No' }}</td>
          <td class="strong">CDI Staff:</td>
          <td>{{ worksheet.cdiStaff }}</td>
        </tr>
        <tr>
          <td class="strong">Auditor Query Affects DRG:</td>
          <td>{{ worksheet.auditQuery ? 'Yes' : 'No' }}</td>
          <td class="strong">Payor: <br />Payor Detail:</td>
          <td style="vertical-align: top;">{{ worksheet.payorName }}<br />{{ worksheet.payorDetail }}</td>
          <td class="strong">D/C Available for Coding:</td>
          <td>{{ worksheet.dcSummAvailAtCoding }}</td>
        </tr>
        <tr>
          <td class="strong">Length of Stay:</td>
          <td>{{ worksheet.los }}</td>
          <td class="strong">Discharge Date:</td>
          <td>{{ formatDate(worksheet.dischargeDate) }}</td>
          <td class="strong">Admit Date:</td>
          <td>{{ formatDate(worksheet.admitDate) }}</td>
        </tr>
        <tr>
          <td class="strong">UB Available:</td>
          <td>{{ worksheet.noUBAvailable ? 'No' : 'Yes' }}</td>
          <td class="strong">Physician:</td>
          <td>{{ worksheet.physician }}</td>
          <td class="strong">Reason for Change:</td>
          <td>{{ worksheet.reasonForChangeDesc }}</td>
        </tr>
      </template>
    </table>

    <hr class="separator" />
    <table class="demographics">
      <tr>
        <td class="strong">Original {{ worksheet.ipReviewType ? worksheet.ipReviewType : 'DRG' }}:</td>
        <td>{{ worksheet.orDRG }}</td>
        <td class="strong">Recommended {{ worksheet.ipReviewType ? worksheet.ipReviewType : 'DRG' }}:</td>
        <td>{{ worksheet.recDRG }}</td>
      </tr>

      <tr>
        <td class="strong">Original {{ worksheet.ipReviewType ? worksheet.ipReviewType : 'DRG' }} Weight:</td>
        <td>{{ worksheet.orWt }}</td>
        <td class="strong">Recommended {{ worksheet.ipReviewType ? worksheet.ipReviewType : 'DRG' }} Weight:</td>
        <td>{{ worksheet.recWt }}</td>
      </tr>

      <tr v-if="worksheet.originalSOI">
        <td class="strong">Original SOI:</td>
        <td>{{ worksheet.originalSOI }}</td>
        <td class="strong">Recommended SOI:</td>
        <td>{{ worksheet.recommendedSOI }}</td>
      </tr>

      <tr v-if="worksheet.originalROM">
        <td class="strong">Original ROM:</td>
        <td>{{ worksheet.originalROM }}</td>
        <td class="strong">Recommended ROM:</td>
        <td>{{ worksheet.recommendedROM }}</td>
      </tr>

      <tr>
        <td class="strong">Original Patient Status:</td>
        <td>{{ worksheet.orPatStat }}</td>
        <td class="strong">Recommended Patient Status:</td>
        <td>{{ worksheet.recPatstat }}</td>
      </tr>
      <tr>
        <td class="strong">Original Point of Origin:</td>
        <td>{{ worksheet.originalPOO }}</td>
        <td class="strong">Recommended Point of Origin:</td>
        <td>{{ worksheet.recommendedPOO }}</td>
      </tr>

      <template>
        <tr>
          <td class="strong">Original Patient Safety Indicators:</td>
          <td>
            <div v-for="psi in worksheet.psis.filter(x => x.reviewLevel === 0)" :key="psi.identifier">
              <a :title="psi.longDescription" :href="psi.href" target="_blank">{{ psi.shortDescription }}</a>
            </div>
          </td>
          <td class="strong">Recommended Patient Safety Indicators:</td>
          <td>
            <div v-for="psi in worksheet.psis.filter(x => x.reviewLevel === 1)" :key="psi.identifier">
              <a :title="psi.longDescription" :href="psi.href" target="_blank">{{ psi.shortDescription }}</a>
            </div>
          </td>
        </tr>
        <tr v-if="worksheet.psis && worksheet.psis.length > 0">
          <td colspan="4">
            <small style="font-style: italic;"> The above Patient Safety Indicator (PSI) information represents potential triggers. Actual PSIs are not able to be calculated
              based on limited data. For more information, see ARHQ.gov <a href="https://qualityindicators.ahrq.gov/Modules/PSI_TechSpec_ICD10_v2020.aspx" target="_blank">PSI
                Technical Specifications</a>. </small>
          </td>
        </tr>
      </template>

      <template>
        <tr>
          <td class="strong">Original Quality Measures:</td>
          <td>
            <div v-for="qm in worksheet.qualityMeasures.filter(x => x.reviewLevel === 0)" :key="qm.identifier">
              <span :title="qm.longDescription">{{ qm.identifier }}</span>
            </div>
          </td>
          <td class="strong">Recommended Quality Measures:</td>
          <td>
            <div v-for="qm in worksheet.qualityMeasures.filter(x => x.reviewLevel === 1)" :key="qm.identifier">
              <span :title="qm.longDescription">{{ qm.identifier }}</span>
            </div>
          </td>
        </tr>
        <tr v-if="worksheet.qualityMeasures && worksheet.qualityMeasures.length > 0">
          <td colspan="4">
            <small style="font-style: italic;"> The above CMS Mortality and Readmission Quality Measure information represents potential triggers. Actual readmission and
              mortality measures are not able to be calculated based on limited data. For more information, see CMS.gov QualityNet <a
                href="https://qualitynet.cms.gov/inpatient/measures/mortality/methodology" target="_blank">Mortality Methodology</a> and <a
                href="https://qualitynet.cms.gov/inpatient/measures/readmission/methodology" target="_blank">Readmission Methodology</a>. </small>
          </td>
        </tr>
      </template>
    </table>

    <template v-if="worksheet.gfLegend && worksheet.gfLegend.length > 0">
      <hr class="separator" />
      <table class="demographics" style="font-size: 12px; font-style: italic;" id="dxCodes">
        <tr>
          <td>Grouper Flags Legend</td>
        </tr>
        <tr>
          <td>
            <el-row v-for="(row, i) in worksheet.gfLegend" :key="i">
              <el-col :span="8">
                <strong>{{ row.col1Key }}</strong> {{ row.col1Value }}
              </el-col>
              <el-col :span="8">
                <strong>{{ row.col2Key }}</strong> {{ row.col2Value }}
              </el-col>
              <el-col :span="8">
                <strong>{{ row.col3Key }}</strong> {{ row.col3Value }}
              </el-col>
            </el-row>
          </td>
        </tr>
      </table>
    </template>
    <table style="margin-top: 5px;">
      <tr style="background-color: lightslategray; color: white; text-align: center;">
        <td style="width: 45%;">Original Dx Coding</td>
        <td style="width: 55%;">Recommended Dx Coding</td>
      </tr>
    </table>

    <table class="stripe">
      <thead>
        <tr style="text-align: left;">
          <th style="width: 8%;">Code</th>
          <th style="width: 5%;">POA</th>
          <th style="width: 25%;">Description</th>
          <th style="width: 7%">HCC</th>
          <th style="width: 8%;">Code</th>
          <th style="width: 5%;">POA</th>
          <th style="width: 25%;">Description</th>
          <th style="width: 7%">HCC</th>
          <th style="width: 10%">Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr :class="{ bold: isDxRowChange(dx) }" v-for="dx in worksheet.dxs" :key="dx.dxId">
          <td style="width: 5%;" :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }">
            {{ dx.originalDx }}
          </td>
          <td :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }">{{ dx.originalPOA }}</td>
          <td :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }">
            {{ dx.origDesc }} <span v-if="dx.originalGrouperFlags" class="grouperFlags">({{ dx.originalGrouperFlags }})</span>
          </td>
          <td :class="{ 'red-font': isOriginalRedFont(dx, worksheet.dxs) }" style="font-size: 13px;">
            <div>{{ dx.originalHccs }} </div>
            <div>{{dx.originalHccsV28}}</div>
          </td>
          <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }" :style="dx.originalDeleted ? 'text-decoration: line-through' : ''">
            {{ dx.recommendedDx }}
          </td>
          <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }">{{ dx.recommendedPOA }}</td>
          <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }">
            {{ getDxRecommendedDescription(dx, worksheet.dxs) }} <span
              v-if="(dx.recommendedDx && dx.recommendedGrouperFlags) || (dx.recommendedGrouperFlags && dx.recommendedGrouperFlags !== dx.originalGrouperFlags)"
              class="grouperFlags">({{ dx.recommendedGrouperFlags }})</span>
          </td>
          <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }" style="font-size: 13px;">
            <div>{{ dx.recommendedHccs }} </div>
            <div>{{dx.recommendedHccsV28}}</div>
          </td>
          <td :class="{ 'red-font': isRecommendedRedFont(dx, worksheet.dxs) }">{{ dx.reasonForChange }}</td>
        </tr>
      </tbody>
    </table>

    <table id="pxCodes">
      <tr style="background-color: lightslategray; color: white; text-align: center;">
        <td style="width: 45%;">Original Px Coding</td>
        <td style="width: 55%;">Recommended Px Coding</td>
      </tr>
    </table>

    <table class="stripe">
      <thead>
        <tr style="text-align: left;">
          <th style="width: 10%;">Code</th>
          <th style="width: 35%;">Description</th>
          <th style="width: 10%;">Code</th>
          <th style="width: 35%;">Description</th>
          <th style="width: 10%">Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr :class="{ 'red-font': px.originalDeleted, bold: isPxRowChange(px) }" v-for="px in worksheet.pxs" :key="px.pxId">
          <td>
            {{ px.originalPx }}
            <span v-if="px.procedureDate" style="display: inline-block; font-style: italic; margin-top: 5px; font-size: 13px;">({{ formatDate(px.procedureDate) }})</span>
          </td>
          <td>
            {{ px.originalDescription }} <span v-if="px.originalGrouperFlags" class="grouperFlags">({{ px.originalGrouperFlags }})</span>
          </td>
          <td :style="px.originalDeleted ? 'text-decoration: line-through' : ''">{{ px.originalDeleted ? px.originalPx : px.recommendedPx }}</td>
          <td>
            {{ px.originalDeleted ? getPxDeletedDescription(px) : px.recommendedDesc }} <span
              v-if="(px.recommendedPx && px.recommendedGrouperFlags) || (px.recommendedGrouperFlags && px.recommendedGrouperFlags !== px.originalGrouperFlags)"
              class="grouperFlags">({{ px.recommendedGrouperFlags }})</span>
          </td>
          <td>{{ px.reasonForChange }}</td>
        </tr>
      </tbody>
    </table>
    <template>
      <table class="" id="findings">
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th style="width: 100%;">Findings</th>
        </tr>
      </table>

      <table>
        <tr>
          <td width="95%">
            <InpatientWorksheetFindings :findings="worksheet.findings"> </InpatientWorksheetFindings>
          </td>
        </tr>
      </table>
    </template>
    <table class="demographics" width="100%" id="coderResponses" v-if="!isCoder && !optionalParameters.hideWorksheetCoderResponses">
      <tbody>
        <tr style="background-color: lightslategray; color: white; text-align: center;">
          <th colspan="6">Coder Responses</th>
        </tr>
        <tr v-if="!worksheet.rebuttal">
          <td colspan="6">
            <div style="font-style: italic; font-size: 14px;">No Responses on Worksheet</div>
          </td>
        </tr>
        <template v-if="worksheet.rebuttal">
          <tr>
            <td class="strong">
              Response Date:
            </td>
            <td>
              {{ formatDate(worksheet.rebuttal.rebuttalDate) }}
            </td>
            <td class="strong">
              Coder Agrees With Changes:
            </td>
            <td>
              {{ worksheet.rebuttal.agreeWithCodes ? 'Yes' : 'No' }}
            </td>
            <td class="strong">
              Final Outcome:
            </td>
            <td>
              {{ worksheet.rebuttal.finalOutcome }}
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="strong" style="margin-bottom: 20px;">Response Comments:</div>
              <div style="margin: 0px 20px 0px 20px;" v-for="comment in worksheet.rebuttal.rebuttalComments" :key="comment.commentId">
                {{ comment.user.fullName }} on <i>{{ format(parseISO(comment.createdDate), 'M/d/yyyy hh:mm a') }}</i>
                <div class="commentRichText" v-html="comment.comment"></div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
  import dateHelper from '@/mixins/date-helper'
  import InpatientWorksheetFindings from './InpatientWorksheetFindings'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'InpatientWorksheet',
    mixins: [dateHelper],
    props: {
      worksheet: {
        type: Object
      },
      isCoder: {
        type: Boolean
      },
      optionalParameters: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    components: {
      InpatientWorksheetFindings
    },
    data() {
      return {
        format: format,
        parseISO: parseISO
      }
    },
    methods: {
      isDxRowChange(dx) {
        if (dx.recommendedDx || dx.originalDeleted || dx.recommendedPOA) {
          return true
        }
        return false
      },
      isPxRowChange(px) {
        if (px.recommendedPx || px.originalDeleted) {
          return true
        }
        return false
      },
      getDxRecommendedDescription(dx, dxs) {
        if (dx.originalDeleted) {
          if (dx.recDesc) {
            return `Delete Diagnosis - ${dx.recDesc}`
          }
          return 'Delete Diagnosis'
        }

        if (dx.recDesc && dx.rowNumber === 1 && dxs.some(x => x.newPrimary)) {
          if (dx.recDesc) {
            return `Resequence Principal Diagnosis - ${dx.recDesc}`
          }
          return 'Resequence Principal Diagnosis'
        }

        return dx.recDesc
      },
      getPxDeletedDescription(px) {
        if (px.originalDescription) {
          return `Delete Procedure - ${px.originalDescription}`
        }
        return 'Delete Procedure'
      },
      isOriginalRedFont(dx, dxs) {
        if (dx.rowNumber === 1 && dxs.some(x => x.newPrimary && x.originalDeleted)) {
          return true
        }

        if (dx.originalDeleted && !dxs.some(x => x.newPrimary)) {
          return true
        }
        return false
      },
      isRecommendedRedFont(dx, dxs) {
        return dx.originalDeleted
      }
    }
  }
</script>

<style scoped>
  .commentRichText {
    border: 1px solid #dcdfe6;
    background: #e5eef4;
    border-radius: 4px;
    padding: 5px;
    margin: 10px 0 20px 0px;
    min-height: 30px;
  }

  .strong {
    font-weight: bold;
  }

  .separator {
    height: 15px;
    background-color: lightslategray;
  }

  .red-font {
    color: red;
  }

  .bold {
    font-weight: bold;
  }

  tr:nth-child(odd) {
    background: unset;
  }

  .worksheet thead {
    /* background-color: unset !important; */
  }

  .grouperFlags {
    font-style: italic;
  }
</style>