<template>
  <div>
    <!-- <div>
      <span><strong>{{tenantSetup.tenantTitle}}</strong></span>
    </div> -->
    <div>
      <img v-if="tenantSetup.imageUrl" style="width: 240px;" :src="tenantSetup.imageUrl">
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { parseISO, isBefore } from 'date-fns'

  export default {
    name: 'TenantReportHeader',
    methods: {
      ...mapActions('managedLists/tenantSetup/', ['GET_TENANT_SETUP']),
      async initialize() {
        if (!this.tenantSetup.imageUrl || this.isImageUrlExpired(this.tenantSetup.imageUrl)) {
          await this.GET_TENANT_SETUP()
        }
      },
      isImageUrlExpired(url) {
        const urlParams = new URLSearchParams(url)
        const expirationDateISOString = urlParams.get('se')

        if (expirationDateISOString) {
          return isBefore(parseISO(expirationDateISOString), new Date())
        }

        return true
      }
    },
    created: function () {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/tenantSetup/', ['tenantSetup'])
    }
  }

</script>
