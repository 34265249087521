<template>
  <div>
    <table width="100%">
      <tr class="scoringHeader">
        <td>Physical Examination</td>
        <td>{{!worksheet.decisionMaking2021 && !worksheet.decisionMaking2023 ? scoring.examDetailType : null}}</td>
        <td>{{!worksheet.decisionMaking2021 && !worksheet.decisionMaking2023 ? scoring.examDetailScore : null}}</td>
      </tr>
      <tr>
        <td>Selected Elements</td>
        <td colspan="2">{{ scoring.examSelected }}</td>
      </tr>
    </table>

  </div>
</template>

<script>
  export default {
    name: 'ProWorksheetPhysicalExam',
    props: ['scoring', 'worksheet'],
    components: {
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped>

</style>