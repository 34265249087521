<template>
  <div>
    <div>
      <table width="100%" style="margin-bottom: 4px;">
        <tr class="scoringHeader">
          <td colspan="2">Chief Complaint Documented</td>
          <td>{{scoring.hpiChiefComplaint}}</td>
        </tr>
      </table>
    </div>

    <table width="100%">
      <tr class="scoringHeader">
        <td colspan="2">History</td>
        <td>{{!worksheet.decisionMaking2021 && !worksheet.decisionMaking2023 ? scoring.historyLevel : null}}</td>
      </tr>
      <tr>
        <td>History of Present Illness</td>
        <td>{{scoring.hpi}}</td>
        <td>{{scoring.hpiSelected}}</td>
      </tr>
      <tr>
        <td>Review of Systems</td>
        <td>{{scoring.ros}}</td>
        <td>{{scoring.rosSelected}}</td>
      </tr>
      <tr>
        <td>Past, Family, and Social History</td>
        <td>{{scoring.pfsh}}</td>
        <td>{{scoring.pfshSelected}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'ProWorksheetHistory',
    props: ['scoring', 'worksheet'],
    components: {
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped>

</style>