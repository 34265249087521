<template>
  <div>
    <div v-for="(finding, index) in findings" :key="index">
      <table class="finding">
        <tr style="background-color: lightgray; color: black; text-align: center;">
          <th>
            <i>{{ finding.codeDesc }}</i
            >{{ ` - ${finding.reasonDescription}` }}
          </th>
        </tr>
      </table>

      <table class="details" style="border-top-left-radius: 0px; border-top-right-radius: 0px;">
        <tbody>
          <tr>
            <td width="11%" style="color: black;">
              Details
            </td>
            <td>
              <div v-html="getRichTextHtml(finding.findingDetails)"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="documentation">
        <tbody>
          <tr>
            <td width="11%" style="color: black;">
              Documentation
            </td>
            <td>
              <div v-html="getRichTextHtml(finding.supportingDoc)"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="reference" style="margin-bottom: 4px;">
        <tbody>
          <tr>
            <td width="11%" style="color: black;">
              Reference
            </td>
            <td>
              <div v-html="getRichTextHtml(finding.reference)"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="findings.length === 0">
      <div style="font-style: italic; font-size: 14px; ">No Findings on Worksheet</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProWorksheetFindings',
    props: ['findings', 'decisionMaking2021'],
    components: {},
    data() {
      return {}
    },
    methods: {
      getRichTextHtml(richText) {
        if (richText) {
          return richText
        }
        return '<i>none</i>'
      }
    }
  }
</script>

<style scoped></style>
