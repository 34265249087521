<template>
  <div>
    <table width="100%">
      <tr class="scoringHeader">
        <td colspan="2">Medical Decision Making</td>
        <td>{{scoring.decisionComplexity}}</td>
      </tr>
      <tr>
        <td>Type and Number of Presenting Problems</td>
        <td>Score: {{scoring.typeAndNumberOfPresentingProblems}}</td>
        <td>{{scoring.typeAndNumberOfPresentingProblemsSelected}}</td>
      </tr>
      <tr>
        <td>Amount and Complexity of Data</td>
        <td>Score: {{scoring.amtandComplexityOfData}}</td>
        <td>{{scoring.amountAndComplexityOfDataSelected}}</td>
      </tr>
      <tr>
        <td>Level of Risk</td>
        <td>Score: {{scoring.levelOfRisk}}</td>
        <td>{{scoring.levelOfRiskSelected}}</td>
      </tr>
      <tr v-if="scoring.timeBasedCalculation">
        <td>Time Based</td>
        <td>Total Time Spent: {{scoring.timeSpentTotal}}</td>
        <td>Time Spent Counseling: {{scoring.timeSpentCounseling}}</td>
      </tr>
    </table>

  </div>
</template>

<script>
  export default {
    name: 'ProWorksheetMDM',
    props: ['scoring'],
    components: {
    },
    data() {
      return {

      }
    }
  }
</script>

<style scoped>

</style>
